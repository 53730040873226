<template>
  <div>
    <v-footer app dark absolute>
      <v-container>
        <v-row justify="start">
          <v-col cols="9" class="pa-0 ma-0">
            <span v-for="(link, i) in links" :key="i">
              <span v-if="i != 0"> &nbsp; {{ delimiter }} &nbsp; </span>
              <a
                v-if="link.href !== ''"
                :class="link.class"
                :target="link.href ? '_blank' : null"
                @click="handleClick(link, $router)"
                :href="link.href ? link.href : 'javascript:void(0)'"
                rel="noreferrer"
                >{{ link.text }}</a
              >
              <span v-else>{{ link.text }}</span>
            </span>
          </v-col>
          <v-col cols="3" class="pa-0 ma-0">
            <span class="grey--text">
              &copy; {{ new Date().getFullYear() }} Kawasaki Motors Corp. All Rights Reserved.
            </span>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Footer",
  data: () => ({
    delimiter: "|",
    links: []
  }),
  methods: {
    handleClick(link, router) {
      const url = link.name ? router.resolve({ name: link.name }).href : link.file;
      if (url) {
        window.open(url, "_blank");
      }
    }
  },
  mounted() {
    this.links = [
      {
        text: "Kawasaki Privacy Policy",
        href: "https://www.kawasaki.com/en-us/about/legal-policies"
      },
      {
        text: "Terms of Use",
        href: "https://www.kawasaki.com/en-us/about/legal-policies#termsConditionsUse"
      },
      {
        text: "About Ads",
        href: "https://www.kawasaki.com/en-us/about/legal-policies#section11"
      },
      {
        text: "Do Not Sell or Share My Personal Information",
        href:
          "https://privacyportal.onetrust.com/webform/045ffde2-647c-41d8-8fd2-848407f04871/15c56c27-7325-4414-b115-28163f6dbc52"
      },
      {
        text: "Children’s Privacy Policy",
        name: "ChildrenPrivacyPolicy"
      },
      {
        class: "optanon-toggle-display",
        text: "Manage Cookie Preferences"
      }
    ];
  },
  computed: {
    ...mapGetters(["selectedProgram"])
  }
};
</script>
